
import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api'
import { CheckCircleIcon, InfoIcon } from '~/components/veke-ui/icons'
export default defineComponent({
  name: 'CustomerAccountBenefits',
  components: { CheckCircleIcon, InfoIcon },
  setup () {
    const route = useRoute()
    const isFromOmaVeke = computed(() => route.value.query.from === 'omaveke')

    return {
      isFromOmaVeke
    }
  },
  head: {
    title: 'Asiakastilin edut'
  }
})
